import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Home from "./components/Home";
import Business from "./components/Business";
import NotFound from "./components/NotFound";
import BusinessNotFound from "./components/BusinessNotFound";

function App() {
  // Check if user came from playafi.biz
  const isFromBiz =
    document.referrer.includes("playafi.biz") ||
    window.location.hostname === "playafi.biz";

  console.log("App Version:", "1.0.1");
  
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={isFromBiz ? <Navigate to="/business" replace /> : <Home />}
        />
        <Route path="/business" element={<Business />} />
        <Route path="/business/:id" element={<BusinessNotFound />} />
        <Route path="/:id" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
