import React, { useEffect, useState } from 'react';
import HeaderBiz from './Header-Biz';
import { Box } from '@mui/material';
import Cookies from 'js-cookie';

const Business = () => {
  const [iframeSrc, setIframeSrc] = useState(`https://playafi-op.carrd.co${window.location.hash}`);
  const [currentHash, setCurrentHash] = useState(window.location.hash);

  useEffect(() => {
    const handleHashChange = () => {
      const currentHash = window.location.hash;
      
      // Check for special join hash
      if (currentHash === '#join') {
        const sponsorId = Cookies.get('business-SponsorID');
        const sponsorName = Cookies.get('business-SponsorName');
        
        if (sponsorId && sponsorName) {
          window.location.href = `https://www.cs4000.me/PlayFi/enroll/signup_form.asp?sponsorid=${sponsorId}&sponsorname=${encodeURIComponent(sponsorName)}`;
        } else {
          window.location.href = 'https://lockit.playafi.com';
        }
        return;
      }
      
      // Check if hash is a replication ID (not a navigation hash)
      if (currentHash && !['#games', '#rewards', '#values', '#home'].includes(currentHash)) {
        // Remove the # from the hash
        const replicationId = currentHash.substring(1);
        console.log('Replication ID:', replicationId);
        
        // Call the replication API
        const functionUrl = `https://checkid-3mxk2k5jta-uc.a.run.app?site=${replicationId}`;
        
        fetch(functionUrl)
          .then(response => response.text())
          .then(data => {
            const str2 = data.split('|');
            console.log('Business Payload:', str2);

            if (str2[0] === '0') {
              // Set business cookies
              Cookies.set('business-SponsorID', str2[1], { path: '/', domain: window.location.hostname });
              Cookies.set('business-SponsorName', str2[2], { path: '/', domain: window.location.hostname });
              Cookies.set('business-SponsorPhone', str2[3], { path: '/', domain: window.location.hostname });
              Cookies.set('business-SponsorEmail', str2[4], { path: '/', domain: window.location.hostname });
              Cookies.set('business-SiteName', str2[5], { path: '/', domain: window.location.hostname });
              Cookies.set('business-PictureFileName', str2[6], { path: '/', domain: window.location.hostname });

              // After setting cookies, load the default business page
              window.location.hash = 'home';
            } else {
              console.error('Not a valid business site name');
            }
          })
          .catch(error => {
            console.error('Error fetching business data:', error);
          });
      } else {
        setIframeSrc(`https://playafi-op.carrd.co${currentHash}`);
        setCurrentHash(currentHash);
      }
    };

    handleHashChange();
    window.addEventListener('hashchange', handleHashChange);
    return () => window.removeEventListener('hashchange', handleHashChange);
  }, []);

  const getTopOffset = () => {
    const hasSponsor = Cookies.get('business-SponsorID');
    
    if (!hasSponsor) {
      return {
        xs: '-20px',
        sm: '-10px',
        md: '-10px',
        lg: '-10px',
        xl: '-10px',
      };
    }

    if (currentHash === '' || currentHash === '#home') {
      return {
        xs: '30px',
        sm: '20px',
        md: '20px',
        lg: '20px',
        xl: '20px',
      };
    }

    return {
      xs: '120px',
      sm: '110px',
      md: '110px',
      lg: '110px',
      xl: '110px',
    };
  };

  return (
    <>
      <HeaderBiz />
      <Box sx={{ 
        position: 'absolute',
        top: getTopOffset(),
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 0,
        overflow: 'visible',
        minHeight: '100vh',
        height: 'auto'
      }}>
        <iframe
          src={iframeSrc}
          style={{ 
            width: '100%',
            minHeight: '100vh',
            height: 'auto',
            border: 'none',
            display: 'block'
          }}
          title="PlayaFi Business"
          scrolling="yes"
        />
      </Box>
    </>
  );
};

export default Business;