import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';

const SiteOwner = ({ business = false }) => {
  const [imageError, setImageError] = useState(false);
  const prefix = business ? 'business-' : '';
  
  const sponsorInfo = {
    id: Cookies.get(`${prefix}SponsorID`) || '',
    name: Cookies.get(`${prefix}SponsorName`) || '',
    email: Cookies.get(`${prefix}SponsorEmail`) || '',
    phone: Cookies.get(`${prefix}SponsorPhone`) || '',
    pictureFileName: Cookies.get(`${prefix}PictureFileName`) || '',
  };

  if (!sponsorInfo.id) return null;

  const handleImageError = () => {
    setImageError(true);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: { xs: 1, sm: 2 },
        color: 'white',
        py: 1,
        width: '100%',
        flexDirection: { xs: 'column', sm: 'row' },
      }}
    >
      <Box
        component="img"
        src={imageError || !sponsorInfo.pictureFileName 
          ? '/Person-Missing-Icon.png' 
          : `https://www.cs4000.me/GPA/images/member/${sponsorInfo.pictureFileName}`
        }
        alt={sponsorInfo.name}
        onError={handleImageError}
        sx={{
          height: { xs: '50px', sm: '60px' },
          width: 'auto',
        }}
      />
      <Box sx={{ textAlign: { xs: 'center', sm: 'left' } }}>
        <Typography 
          sx={{ 
            fontSize: { xs: '0.9rem', sm: '1rem' },
            fontWeight: 400,
          }}
        >
          Welcome to the website of <strong>{sponsorInfo.name}</strong>
        </Typography>
        <Typography 
          sx={{ 
            fontSize: { xs: '0.75rem', sm: '0.85rem' },
            fontWeight: 200,
            display: 'flex',
            alignItems: 'center',
            justifyContent: { xs: 'center', sm: 'flex-start' },
            gap: 2,
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
            <PhoneIcon sx={{ 
              fontSize: '0.9rem',
              opacity: 0.6,
            }} />
            {sponsorInfo.phone}
          </Box>
          |
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
            <EmailIcon sx={{ 
              fontSize: '0.9rem',
              opacity: 0.6,
            }} />
            <a
              href={`mailto:${sponsorInfo.email}`}
              style={{ 
                color: 'white', 
                textDecoration: 'none',
                fontWeight: 200,
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
              }}
            >
              {sponsorInfo.email}
            </a>
          </Box>
        </Typography>
      </Box>
    </Box>
  );
};

SiteOwner.propTypes = {
  business: PropTypes.bool
};

SiteOwner.defaultProps = {
  business: false
};

export default SiteOwner; 