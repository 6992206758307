import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { Box } from '@mui/material';

const BusinessNotFound = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Extract the path from the URL, removing 'business/'
    const path = window.location.pathname.replace('/business/', '');

    console.log('Business Replication ID:', path);
    const functionUrl = `https://checkid-3mxk2k5jta-uc.a.run.app?site=${path}`;
    console.log('Function URL:', functionUrl);

    // Fetch data from the Firebase Function
    fetch(functionUrl)
      .then(response => response.text())
      .then(data => {
        const str2 = data.split('|');
        console.log('Business Payload:', str2);

        if (str2[0] === '0') {
          // Set cookies with business- prefix
          Cookies.set('business-SponsorID', str2[1], { path: '/', domain: window.location.hostname });
          Cookies.set('business-SponsorName', str2[2], { path: '/', domain: window.location.hostname });
          Cookies.set('business-SponsorPhone', str2[3], { path: '/', domain: window.location.hostname });
          Cookies.set('business-SponsorEmail', str2[4], { path: '/', domain: window.location.hostname });
          Cookies.set('business-SiteName', str2[5], { path: '/', domain: window.location.hostname });
          Cookies.set('business-PictureFileName', str2[6], { path: '/', domain: window.location.hostname });

          // Redirect to the business page
          navigate('/business');
        } else {
          console.error('Not a valid business site name');
        }
      })
      .catch(error => {
        console.error('Error fetching business data:', error);
      });
  }, [navigate]);

  return (
    <Box
      sx={{
        backgroundColor: '#141414',
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        component="img"
        src="/Logo-404.png"
        alt="404 Not Found"
        sx={{
          width: '50%', // Set width to 50% of the screen
          maxWidth: '500px', // Limit the maximum width to 500px
          height: 'auto', // Maintain aspect ratio
        }}
      />
    </Box>
  );
};

export default BusinessNotFound; 