import React, { useEffect, useState } from 'react';
import Header from './Header';
import { Box } from '@mui/material';
import Cookies from 'js-cookie';

const Home = () => {
  const [iframeSrc, setIframeSrc] = useState(`https://playafi.carrd.co${window.location.hash}`);
  const [currentHash, setCurrentHash] = useState(window.location.hash);

  useEffect(() => {
    const handleHashChange = () => {
      const newHash = window.location.hash;
      console.log('Hash changed:', newHash);
      setCurrentHash(newHash);
      setIframeSrc(`https://playafi.carrd.co${newHash}`);
    };

    console.log('Initial load hash:', window.location.hash);

    window.addEventListener('touchstart', () => {}, { passive: true });
    window.addEventListener('hashchange', handleHashChange);

    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, []);

  // Calculate top offset based on hash and sponsor status
  const getTopOffset = () => {
    const hasSponsor = Cookies.get('SponsorID');
    
    if (!hasSponsor) {
      return {
        xs: '-20px',
        sm: '-10px',
        md: '-10px',
        lg: '-10px',
        xl: '-10px',
      };
    }

    if (currentHash === '' || currentHash === '#home') {
      return {
        xs: '30px',
        sm: '20px',
        md: '20px',
        lg: '20px',
        xl: '20px',
      };
    }

    return {
      xs: '120px',
      sm: '110px',
      md: '110px',
      lg: '110px',
      xl: '110px',
    };
  };

  return (
    <>
      <Header />
      <Box sx={{ 
        position: 'absolute',
        top: getTopOffset(),
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 0,
        overflow: 'visible',
        minHeight: '100vh',
        height: 'auto'
      }}>
        <iframe
          src={iframeSrc}
          style={{ 
            width: '100%',
            minHeight: '100vh',
            height: 'auto',
            border: 'none',
            display: 'block'
          }}
          title="PlayaFi"
          scrolling="yes"
        />
      </Box>
    </>
  );
};

export default Home;